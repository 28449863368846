import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { Typography } from "@material-ui/core"

// import app components
import Form from "../../components/form"
import MapBox from "../../components/map"
import {
  Box,
  Content,
  Headline
} from "../../components/flexibleContent/LinkBoxes"
import BackgroundImage from "../../components/backgroundImage"
import Edges from "../../components/edges"

import useFooterQuery from "../../components/footer/useFooterQuery"

export default function Contact(props) {
  const {
    wpPage: {
      id,
      title,
      slug,
      uri,
      template: {
        templateContactUs: {
          fieldGroupName,
          formId,
          links,
          map,
          headline,
          link
        }
      }
    }
  } = props.data

  const {
    themeOptions: {
      siteOptions: {
        email,
        addressLine1,
        addressLine2,
        postalCode,
        phoneNumber
      }
    }
  } = useFooterQuery()

  //   const wpUrl = pageContext?.wpUrl

  return (
    <Container>
      <Header>{!!title && <PostTitle variant="h1">{title}</PostTitle>}</Header>

      <MapForm>
        <MapWrapper>
          <MapBox
            className="map-box"
            defaultViewport={{
              latitude: map.latitude,
              longitude: map.longitude,
              zoom: parseInt(map.zoom) || 11.5
            }}
          />
        </MapWrapper>
        <Form className="contact-form" {...{ uri, formId }} />
      </MapForm>

      <ContactInfo size="lg">
        <Column>
          <Title variant="h5" gutterBottom>
            Phone
          </Title>
          <a href={phoneNumber.url}>
            <IntroText>{phoneNumber.title}</IntroText>
          </a>
        </Column>
        <Column>
          <Title variant="h5" gutterBottom>
            Address
          </Title>
          <Address variant="h4">{addressLine1}</Address>
          <Address variant="h4">{addressLine2}</Address>
          <Address variant="h4">{postalCode}</Address>
        </Column>
        <Column>
          <Title variant="h5" gutterBottom>
            Email
          </Title>
          <a href={"mailto:" + email}>
            <IntroText>{email}</IntroText>
          </a>
        </Column>
      </ContactInfo>

      {!!links && (
        <Links>
          {links.map(({ link, headline, image }, index) => {
            // link, headline, description, image, size
            return (
              <Box
                className="box"
                size="small"
                key={index}
                to={link ? link.url : null}
                {...{ index }}
              >
                <Content>
                  {!!headline && (
                    <Headline
                      component="div"
                      variant="h1"
                      color="inherit"
                      gutterBottom
                    >
                      {headline}
                    </Headline>
                  )}
                </Content>
                {!!image && (
                  <BackgroundImage
                    className="bg-img"
                    bg={image}
                    position="absolute"
                  />
                )}
              </Box>
            )
          })}
        </Links>
      )}
    </Container>
  )
}

const Container = styled.div`
  background: ${({ theme }) => theme.colors.springWood};
`
const PostTitle = styled(Typography)`
  text-transform: uppercase;
`
const Header = styled.section`
  padding: 30px 0;

  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.sm}px) {
    padding: 60px 0;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.md}px) {
    padding: 100px 0;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const MapForm = styled.section`
  display: flex;
  width: 100%;
  min-height: 400px;
  flex-direction: column-reverse;
  .form-container {
    margin-bottom: 30px;
    padding: 0 20px;
    width: 100%;
    textarea {
      min-height: 120px;
    }
    @media (min-width: 800px) {
      width: 50%;
    }
  }
  @media (min-width: 800px) {
    /* height: calc(100vh - 102px - 284px); */
    flex-direction: row;
  }
`
const MapWrapper = styled.div`
  @media (min-width: 800px) {
    flex-shrink: 0 !important;
    width: 50% !important;
  }
  @media (max-width: 799px) {
    margin-top: 50px;
    height: 300px;
    width: 100%;
    .mapbox {
      height: 300px;
    }
  }
`

const ContactInfo = styled(Edges)`
  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }
  @media (min-width: 800px) {
    padding: 80px 0;
  }
  padding: 40px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  z-index: 2;
  @media (min-width: 800px) {
    flex-direction: row;
  }
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: -50vw;
    width: 180vw;
    height: 100%;
    background: white;
    z-index: -1;
  }
`
const Column = styled.div`
  @media (max-width: 800px) {
    margin-bottom: 30px;
  }
`
const Title = styled(Typography)`
  text-align: center;
`
const Address = styled(Typography)`
  text-align: center;
`
const IntroText = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 24px;
`

const Links = styled.section`
  @media (min-width: 800px) {
    flex-direction: row;
  }
  display: flex;
  flex-direction: column;
  .box {
    &:first-of-type {
      border-right: 1px solid ${({ theme }) => theme.colors.springWood};
    }
    min-height: 328px;
    height: 20vw;
  }
`
export const CollectionQuery = graphql`
  query ContactPageTemplate($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      slug
      uri
      template {
        ... on WpTemplate_Contact {
          templateContactUs {
            fieldGroupName
            formId
            links {
              fieldGroupName
              headline
              link {
                target
                title
                url
              }
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      quality: 90
                      placeholder: BLURRED
                      formats: AUTO
                    )
                  }
                }
              }
            }
            map {
              city
              country
              zoom
              streetNumber
              streetName
              streetAddress
              stateShort
              state
              postCode
              placeId
              longitude
              latitude
              countryShort
            }
          }
        }
      }
    }
  }
`
